import type { ClassValue } from "clsx";

import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function isEmpty<T>(value: string | T[]): boolean {
  return value === "" || value.length === 0;
}

export type ClassValueRecord<Key extends string> = Partial<
  Record<Key, ClassValue>
>;

/**
 * Uses clsx to combine the class names into a single string, then uses twMerge
 * to dedupe Tailwind classes for the same property based on the order they
 * appear in the string.
 * @param classValues The classes to merge
 * @returns The merged classes as a single string
 */
export function cn(...classValues: ClassValue[]) {
  return twMerge(clsx(...classValues));
}

/**
 * Format a string as a EIN, using the normal format of XX-XXXXXXX.
 * @param ein The EIN string to format
 * @returns The formatted EIN
 */
export function formatEIN(ein: string) {
  if (ein.length < 3) return ein;
  return `${ein.slice(0, 2)}-${ein.slice(2)}`;
}

/**
 * Converts a number to an alpha character based on the position and going back to "A" after it reaches the end of the alphabet.
 * @param {number} num - The number to convert to an alpha character. Must be a positive integer.
 * @returns {string} The alpha character corresponding to the input number.
 * @throws {TypeError} If the input number is not a positive integer
 */
export function numberToAlpha(num: number): string {
  if (!Number.isInteger(num) || num <= 0) {
    throw new TypeError("Input must be a positive integer.");
  }

  let baseCharCode = "A".codePointAt(0);
  if (!baseCharCode) throw new Error("Could not get char code for A");
  let alphabetLength = 26;
  let charIndex = (num - 1) % alphabetLength;
  let charCode = baseCharCode + charIndex;

  return String.fromCodePoint(charCode);
}
